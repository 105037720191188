<script setup lang="ts">

</script>

<template>
    <div>
        <slot />
    </div>
</template>


<style scoped></style>